<template>
  <div class="rectangledropdown_inner">
    <div class="form-check" v-for="(item, inx) in options" :key="inx">
      <input class="form-check-input" type="checkbox" :id="`inlineCheckr-${inx}`" @click="() => choose(item.code)" :checked="selected.includes(item.code)">
      <label class="form-check-label" :for="`inlineCheckr-${inx}`">{{ item.name }} - <strong>{{item.code}}</strong></label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'airline-selection',
  props: ['selected', 'options'],
  model: {
    prop: 'selected',
    event: 'change',
  },
  methods: {
    choose(code) {
      const inx = this.selected.indexOf(code);
      if (inx === -1) {
        this.selected.push(code);
      } else {
        this.selected.splice(inx, 1);
      }
      this.$emit('change', this.selected);
    },
  },
};

</script>
